module.exports = {
    "dashboard": "Papan Pemuka ",
    "myAccounts": "Akaun Saya ",
    "internationalTransfer": "Pemindahan Antarabangsa",
    "fundingType": "Jenis Pembiayaan",
    "deposit": "Simpanan ",
    "withdraw": "Mengeluarkan  ",
    "invoice": "Invois ",
    "requestMoney": "Pemintaan Wang ",
    "recipients": "Penerima ",
    "developers": "Pemaju",
    "myProfile": "Profil saya",
    "logout": "Log keluar",
    "qucikBooks": "QuickBooks",
    "profileDetails": "Butiran Profil",
    "password": "Kata Laluan ",
    "users": "Pengguna ",
    "qbDownloads": "Pemuat Turun QB ",
    "transfer": "Pemindahan",
    "allTransactions": "Semua Urus Niaga",
    "recurringTrans": "Transaksi Berulang",
    "firstName": "Name Pertama",
    "lastName": "Nama Keluarga ",
    "emailAddress": "Alamat E-Mel",
    "userRole": "Peranan Pengguna ",
    "settings": "Tetapan ",
    "oldPass": "Kata Laluan Lama ",
    "newPass": "Kata Laluan Baharu ",
    "confirmPass": "Sahkan Kata Laluan",
    "passwordRequirements": "Keperluan Kata Laluan",
    "reqOne": "Sekurang-kurangnya 8 aksara",
    "reqTwo": "Sekurang-kurangnya satu huruf kecil",
    "reqThree": "Sekurang-kurangnya satu huruf besar",
    "reqFour": "Sekurang-kurangnya satu nombor",
    "reqFive": "Sekurang-kurangnya satu aksara berlainan ",
    "reqSix": "Sekurang-kurangnya satu aksara berlainan ",
    "reqSeven": "Tiada Ruang ",
    "saveChanges": "Simpan Perubahan",
    "onlyIntAccounts": "Hanya Akaun Antarabangsa",
    "availableBalance": "Baki yang ada ",
    "currentBalance": "Baki terkini",
    "lastUpdatedDate": "Tarikh Kemas Kini Terakhir",
    "channel": "Saluran ",
    "webhook": "Webhook ",
    "recipientName": "Nama Penerima ",
    "aCHAccountNumber": "Nombor Akaun ACH",
    "wireAccountNumber": "Nombor Akaun Wire",
    "aPIKey": "Kunci API",
    "clientName": "Nama Pelanggan ",
    "createdBy": "Dihasilkan Oleh ",
    "transactionID": "ID Transaksi",
    "sourceType": "Jenis sumber",
    "accountReference": "Rujukan Akaun",
    "routingReference": "Rujukan Penghalaan",
    "expiryDate": "Tarikh luput",
    "recipientEmail": "E-Mel Penerima ",
    "creationDate": "Tarikh Penciptaan",
    "startDate": "Tarikh Pemulaan ",
    "reference": "Rujukan ",
    "transactionType": "Jenis Transaksi",
    "name": "Nama",
    "phone": "Nombor Telefon",
    "role": "Peranan",
    "complete": "Lengkap",
    "failure": "Gagal",
    "returns": "Pemulangan ",
    "count": "Kiraan ",
    "accounts": "Akaun ",
    "noAccounts": "Tiada Akaun ",
    "transactionsType": "Jenis Transaksi",
    "mostRecentTransactions": "Transaksi Terkini",
    "export": "eksport",
    "transactionHistory": "sejarah transaksi",
    "transactionReference": "Rujukan Transaksi",
    "parentReference": "Rujukan Ibu Bapa",
    "paymentReference": "Rujukan pembayaran",
    "amount": "Jumlah ",
    "invoiceNumber": "Nombor invois",
    "feeAmount": "Jumlah Yuran",
    "taxAmount": "Jumlah cukai",
    "exciseTax": "Cukai Eksais",
    "salesTax": "Cukai jualan",
    "registrationFee": "Yuran pendaftaran",
    "serviceFee": "Bayaran perkhidmatan",
    "conversionRate": "Kadar penukaran",
    "oFACMatch": "Padanan OFAC",
    "type": "Jenis ",
    "recurringReference": "Rujukan Berulang",
    "status": "Status",
    "created": "Dicipta",
    "account": "Akaun ",
    "receiver": "Penerima ",
    "description": "Penerangan ",
    "paymentType": "Jenis pembayaran",
    "from": "Daripada",
    "sendVia": "Hantar Melalui",
    "recipientEmailAddress": "Alamat E-mel Penerima",
    "recipientPhoneNumber": "Nombor Telefon Penerima",
    "recipientAccountNumber": "Nombor Akaun Penerima",
    "confirmAccountNumber": "Sahkan Nombor Akaun",
    "to": "Kepada",
    "currency": "Mata Wang ",
    "direction": "Arah",
    "paymentDate": "Tarikh pembayaran",
    "service": "Perkhidmatan",
    "frequency": "Kekerapan",
    "endDate": "Tarikh Akhir",
    "numberofTransfers": "Bilangan Pemindahan",
    "internalDescription": "Penerangan Dalaman",
    "accountNumber": "Nombor Akaun ",
    "pc_accountNumber": "Nombor Akaun ",
    "fileName": "Nama fail",
    "recipient_email": "E-Mel Penerima ",
    "createdDate": "Tarikh Dicipta",
    "dueDate": "Tarikh tamat tempoh",
    "memo": "Memo",
    "action": "Tindakan",
    "email": "E-Mel",
    "phoneNumber": "Nombor Telefon",
    "bankName": "Name Bank ",
    "recipientType": "Jenis Penerima ",
    "fromAccountNumber": "Daripada Nombor Akaun",
    "nameonCard": "Nama pada kad",
    "zipCode": "Poskod ",
    "accountName": "Nama Akaun ",
    "routingNumber": "Nombor Penghalaan",
    "accountType": "Jenis Akaun ",
    "address": "Alamat ",
    "city": "Bandar ",
    "country": "Negara",
    "state": "Negeri",
    "verificationType": "Jenis Pengesahan",
    "debitAmount": "Jumlah Debit",
    "firstCreditAmount": "Jumlah Kredit Pertama",
    "secondCreditAmount": "Jumlah Kredit Kedua",
    "nickName": "Nama Samaran",
    "beneficiaryBankCode": "Kod Bank Penerima",
    "addressLine1": "Alamat Baris 1",
    "addressLine2": "Alamat Baris 2",
    "paymentMethod": "Kaedah Pembayaran",
    "referenceID": "ID Rujukan",
    "aCHDetails": "Butiran ACH",
    "wireDetails": "Butiran Wayar",
    "internationalTransferDetails": "Butiran Pemindahan Antarabangsa",
    "selectDate": "Pilih Tarikh",
    "sender": "penghantar",
    "remarks": "Penyata ",
    "buyorSell": "Beli atau Jual ",
    "toAccountNumber": "Kepada Nombor Akaun ",
    "paymentInstrument": "Alat Pembayaran ",
    "fromaccount": "Daripada Akaun ",
    "toCounterparty": "Kepada Rakan Niaga",
    "purposeofPayment": "Tujuan Pembayaran",
    "fXRate": "Kadar FX",
    "fXAmount": "Jumlah FX ",
    "fXCurrency": "Mata Wang FX ",
    "toName": "Kepada Nama ",
    "toAccount": "Kepada Akaun ",
    "nextTransactionDate": "Tarikh Urus Niaga Seterusnya",
    "aPICode": "Kod API",
    "publicKey": "Kunci Awam",
    "privateKey": "Kunci Peribadi",
    "optional": "Pilihan ",
    "additionalInformation": "Maklumat tambahan",
    "createPayment": "Buat Pembayaran",
    "downloadPDF": "Download PDF",
    "resend": "Hantar semula",
    "approve": "Terima",
    "decline": "Ditolak",
    "addCard": "Tambah Kad ",
    "addBankAccount": "Tambah Akaun Bank ",
    "addNewRecipient": "Tambah Penerima Baharu ",
    "synchronizeQuickBooks": "segerakkan QuickBooks",
    "create": "Cipta",
    "newUser": "Pengguna Baru ",
    "generate": "Menjana",
    "wITHDRAW": "Mengeluarkan  ",
    "confirm": "Sahkan ",
    "cancel": "Batal",
    "close": "Tutup",
    "sendResetInstructions": "Hantar Arahan Set Semula",
    "logIn": "Log Masuk ",
    "resetPassword": "Menetapkan semula kata laluan",
    "setPassword": "Tetapkan kata laluan",
    "signUp": "Daftar",
    "forgotPassword": "Lupa kata laluan",
    "backToLogin": "Kembali untuk log masuk",
    "enterOtp": "Masukkan Kod Pengesahan",
    "resendCode": "Hantar kod baharu",
    "selectPayment": "Pilih Jenis Pembayaran",
    "scheduleRecurringTransaction": "Jadualkan Transaksi Berulang",
    "forgotPassText": "Masukkan e-mel yang dikaitkan dengan akaun anda dan kami akan menghantar e-mel dengan arahan untuk menetapkan semula kata laluan anda.",
    "unableToCP": "Tidak dapat membuat pembayaran",
    "plsSelectPaymentTyp": "Sila pilih jenis pembayaran",
    "plsSelectFromAcc": "Sila pilih daripada akaun",
    "selectPaymentPayment": "Sila pilih jenis pembayaran",
    "enterEmail": "Sila masukkan Alamat E-mel.",
    "emailValidation": "Alamat E-mel tidak boleh melebihi 320 Aksara",
    "enterPhone": "Sila masukkan nombor telefon yang sah",
    "enterAccNum": "Sila masukkan Nombor Akaun",
    "AccNum": "Sila masukkan Nombor Akaun",
    "selectRecipientName": "Sila pilih nama penerima",
    "enterAmounttoTransfer": "Sila masukkan jumlah untuk dipindahkan",
    "enterCurrency": "Sila masukkan mata wang untuk dipindahkan ",
    "selectDirection": "Sila pilih tuju arah",
    "selectPaymentDate": "Sila pilih tarikh pembayaran",
    "selectPaymentdate": "Sila pilih tarikh pembayaran",
    "selectServiceType": "Sila pilih jenis perkhidmatan ",
    "selectFrequency": "Sila pilih kekerapan ",
    "enterAmount": "Sila masukkan jumlah ",
    "selectTxnDate": "Sila masukkan tarikh urus niaga ",
    "selectRecipientType": "Sila pilih jenis penerima",
    "enterEmailAddress": "Sila masukkan Alamat E-mel",
    "validEmail": "Sila masukkan alamat E-mel yang sah",
    "EmailValidation": "Alamat E-mel tidak boleh melebihi 320 Aksara",
    "selectFrmAcc": "Sila pilih daripada akaun",
    "invoiceAlpha": "Nombor invois haruslah dalam bentuk abjad angka",
    "enterInvoiceNum": "Sila masukkan Nombor Invois",
    "invoiceValidation": "Nombor Invois tidak boleh melebihi 150 Aksara",
    "amountToTransfer": "PSila masukkan jumlah yang hendak dipindahkan",
    "valueForMemo": "Sila masukkan nilai untuk Memo",
    "memoValidation": "Memo tidak boleh melebihi 255 Aksara",
    "selectPaymentType": "Please select the payment type",
    "enterName": "Sila masukkan nama",
    "ValidName": "Sila masukkan nama yang sah",
    "nameValidation": "Nama tidak boleh kurang daripada 3 aksara",
    "Name": "Sila masukkan nama",
    "validname": "Sila masukkan nama yang sah",
    "nameValid": "Nama tidak boleh kurang daripada 3 aksara",
    "enterZipCode": "Sila masukkan kod pos",
    "validZipCode": "Sila masukkan poskod yang sah",
    "validzipcode": "Poskod tidak boleh kurang daripada 5 digit",
    "validAccName": "Sila masukkan Nama Akaun",
    "enterValidAccName": "Sila masukkan nama Akaun yang sah",
    "maxAccName": "Nama akaun tidak boleh melebihi 20 digit",
    "minAccName": "Nama akaun tidak boleh kurang daripada 4 digit",
    "accNum": "Please enter the Account Number",
    "validAccNum": "Sila masukkan Nombor Akaun yang sah",
    "maxAccNum": "Nombor akaun tidak boleh melebihi 20 digit",
    "japanMaxAccNum": "Nombor akaun tidak boleh melebihi 35 digit.",
    "japanMaxBankName": "Nama Bank tidak boleh melebihi 35 digit.",
    "japanMinSwiftCode": "Kod Swift hendaklah sekurang-kurangnya 8 aksara",
    "japanMinAddress": "Alamat tidak boleh kurang daripada 2 Aksara",
    "japanMaxCity": "Bandar tidak boleh lebih daripada 35 Aksara.",
    "minAccNum": "Nombor akaun tidak boleh kurang daripada 4 digit",
    "routingNum": "Please enter the Routing Number",
    "validRoutingNum": "Sila masukkan Nombor Penghalaan yang sah",
    "minRoutingNum": "Routing number cannot be less than 9 digits",
    "ValidRoutingNum": "Sila masukkan Nombor Penghalaan yang sah",
    "Acctype": "Sila pilih Jenis Akaun",
    "validAddress": "Sila masukkan alamat yang sah",
    "maxAddress": "Alamat tidak boleh melebihi 400 Aksara",
    "minAddress": "Alamat tidak boleh kurang daripada 5 Aksara",
    "City": "Sila masukkan nama bandar anda.",
    "maxCity": "Nama Bandar tidak boleh melebihi 60 Aksara",
    "countryName": "Sila Pilih Nama Negara",
    "stateName": "Sila Pilih Nama Negeri",
    "validzipCode": "Please enter a valid Zip Code",
    "selectVerificationType": "Sila pilih Jenis Pengesahan",
    "debitAmnt": "Sila masukkan jumlah debit",
    "creditAmnt": "Sila masukkan jumlah kredit",
    "CreditAmount": "Sila masukkan jumlah kredit",
    "AccName": "Sila masukkan Nama Akaun",
    "recipienttype": "Sila pilih jenis penerima",
    "accNumber": "Sila masukkan Nombor Akaun",
    "validAccnum": "Sila masukkan Nombor Akaun yang sah",
    "maxAccNumber": "Account number cannot be more than 20 digits",
    "minAccNumber": "Nombor akaun tidak boleh kurang daripada 4 digit",
    "beneficiaryBankcode": "Sila masukkan Kod Bank Penerima",
    "minRoutingNumber": "Nombor penghalaan tidak boleh kurang daripada 9 digit",
    "acountNumber": "Sila masukkan Nombor Akaun",
    "maxAccnum": "Nombor akaun tidak boleh melebihi 20 digit",
    "minAccnum": "Nombor akaun tidak boleh kurang daripada 4 digit",
    "routingnum": "Sila masukkan Nombor Penghalaan",
    "maxRoutingnum": "Nombor penghalaan tidak boleh kurang daripada 9 digit",
    "acctype": "Sila pilih Jenis Akaun",
    "accountNum": "Sila masukkan Nombor Akaun",
    "validaccountNum": "Sila masukkan Nombor Akaun yang sah",
    "maxAccountNum": "Nombor akaun tidak boleh melebihi 20 digit",
    "minAccountNum": "Nombor akaun tidak boleh kurang daripada 4 digit",
    "enterRoutingNum": "Please enter the Routing Number.",
    "minRouting": "Nombor penghalaan tidak boleh kurang daripada 9 digit",
    "enterAddressLine1": "Sila masukkan Alamat Baris 1",
    "maxAddrss": "Alamat tidak boleh melebihi 100 Aksara.",
    "minAddrss": "Alamat tidak boleh kurang daripada 5 Aksara",
    "enterCity": "Sila masuk nama Bandar",
    "selectCountryName": "Sila Pilih Nama Negara",
    "selectStateName": "Sila Pilih Nama Negeri",
    "validZipcode": "Sila masukkan Poskod yang sah",
    "valdAccnum": "Sila masukkan Nombor Akaun yang sah",
    "countryname": "Sila Pilih Nama Negara",
    "statename": "Sila Masukkan Nama Negeri yang Sah",
    "selectpaymentMethod": "Sila Pilih kaedah Pembayaran",
    "validPhone": "Sila masukkan nombor telefon yang sah",
    "inpFname": "Sila masukkan nama pertama anda",
    "inpLname": "Sila masukkan nama keluarga anda",
    "inpEmail": "Sila masukkan e-mel anda",
    "valiemail": "Sila masukkan e-mel yang sah",
    "validFname": "Sila masukkan nama pertama yang sah",
    "maxFname": "Nama Pertama tidak boleh melebihi 50 Aksara",
    "minFname": "Minimum 3 aksara diperlukan",
    "validLname": "Sila masukkan nama keluarga yang sah",
    "maxLname": "Nama keluarga tidak boleh melebihi 50 Aksara",
    "minLname": "Minimum 3 aksara diperlukan",
    "phoneNum": "Sila masukkan nombor telefon",
    "validPhoneNum": "Sila masukkan nombor telefon yang sah",
    "maxPhone": "Nombor telefon tidak boleh melebihi 10 digit",
    "minPhone": "Nombor telefon mestilah 10 digit.",
    "fromAcc": "Sila pilih daripada akaun",
    "selectstartDate": "Sila pilih tarikh mula",
    "newPsw": "Kata Laluan Baharu Disimpan",
    "unablePsw": "Tidak dapat mengemas kini Kata Laluan",
    "inpOldPsw": "Sila masukkan kata laluan lama anda",
    "inpNewPsw": "Sila masukkan kata laluan baharu anda",
    "maxPsw": "Kata laluan mestilah lebih panjang daripada 8 aksara",
    "newPassword": "Sila sahkan kata laluan baharu anda",
    "result": "Hasil ",
    "buyOrsell": "Sila pilih Beli atau Jual",
    "frmAccNum": "Sila pilih daripada nombor akaun",
    "selectaccNum": "Sila pilih ke nombor akaun",
    "paymentInst": "Sila pilih alat pembayaran",
    "instType": "Sila pilih jenis alat ",
    "insufficientFunds": "Kekurangan dana",
    "unablePayment": "Tidak dapat membuat pembayaran",
    "selectfundingType": "Sila pilih Jenis Pembiayaan",
    "amntTrans": "Sila masukkan jumlah untuk dipindahkan",
    "counterParty": "Sila pilih rakan niaga",
    "purposePayment": "Sila pilih tujuan pembayaran",
    "toAcc": "Sila pilih ke akaun",
    "validUrl": "Sila masukkan Url yang sah",
    "all": "Semua",
    "loading": "Memuatkan…",
    "transactions": "Transaksi",
    "noAccountData": "Tiada Data Akaun",
    "creditTransfer": "Pemindahan kredit",
    "noTransactionData": "Tiada Data Transaksi",
    "productCredit": "Kredit Produk",
    "refresh": "Segarkan semula",
    "UWallets": "Uwallet",
    "UWalletNumber": "Nombor Uwallet",
    "CreditID": "ID kredit",
    "InstrumentType": "Jenis Instrumen",
    "SelectCurrency": "Pilih Mata Wang",
    "select": "Pilih",
    "CardType": "Jenis kad",
    "pleaseselecttheCardtype": "Sila pilih jenis Kad",
    "CardNumber": "Nombor kad",
    "ExpirationDate": "Tarikh luput",
    "CVV": "CVV",
    "validCardNumber": "Sila masukkan Nombor Kad yang sah",
    "failedAddCard": "Gagal menambah kad",
    "cardAddedSuccessfully": "Kad berjaya ditambahkan",
    "validExpiration": "Sila masukkan Tarikh Tamat Tempoh yang sah",
    "validCVV": "Sila masukkan Kod CVV yang sah",
    "GoBack": "Pergi balik",
    "Confirmation": "Pengesahan",
    "NoPaymentInstrumentData": "Tiada Instrumen Pembayaran Ditemui",
    "deactivatePayment": "Adakah anda ingin menyahaktifkan Instrumen Pembayaran yang dipilih?",
    "accountVerified": "Berjaya!! Butiran akaun berjaya disahkan !!",
    "accountNotVerified": "Maaf!! Butiran akaun tidak dapat disahkan.",
    "validAmount": "Sila masukkan jumlah yang sah",
    "noConnectorFound": "Tiada Penyambung tersedia",
    "noCountryFound": "Tiada Senarai Negara ditemui",
    "wentWrongText": "Sesuatu telah berlaku!",
    "noStateFound": "Tiada Senarai Negeri ditemui",
    "noteText": "Nota: Pengesahan Akaun Bank menggunakan proses Deposit Cabaran akan mengambil masa sehingga 5 hari Bekerja",
    "accountalreadyExists": "Nombor akaun sudah wujud",
    "FeatureType": "Jenis Ciri",
    "validFeatureType": "Sila pilih Jenis Ciri.",
    "NoPaymentChannel": "Tiada Saluran Pembayaran Ditemui",
    "bankAddedsuccess": "Akaun bank berjaya ditambahkan",
    "bankAddedFailed": "Gagal menambah akaun bank",
    "limitBreach": "Pelanggaran Had",
    "withdrawMethodSelect": "Pilih Kaedah Tarik Balik",
    "totalAmount": "Jumlah keseluruhan",
    "decimalpointErr": "Maksimum 7 digit diterima sebelum titik perpuluhan.",
    "termsandCondition": "Harus menerima terma dan syarat.",
    "agreeText": "Saya bersetuju dengan",
    "termsText": "terma dan syarat.",
    "minAmountTextAch": "Jumlah pengeluaran Min ialah ",
    "withdrawFeeText1": "Yuran pengeluaran sebanyak ",
    "withdrawFeeText2": " akan dikenakan.",
    "maxAmountWithdrawTxt1": "Jumlah pengeluaran maksimum ialah ",
    "maxAmountWithdrawTxt2": " setiap permintaan.",
    "withdrawSuccess": "Pengeluaran Berjaya!",
    "createAnotherPay": "Buat Bayaran Lain",
    "selectCurrency": "Sila pilih Mata Wang",
    "lowPayquickerBalance": "Baki bayar cepat anda terlalu rendah untuk membuat pembayaran",
    "SelectPaymentMethod": "Pilih Kaedah Pembayaran",
    "availablePayBalance": "Baki Payquicker Tersedia",
    "fundsLoadedSuccess": "Dana berjaya dimuatkan!",
    "invalidAccDetails": "Butiran Akaun Tidak Sah",
    "insufficientAccBalance": "Baki Akaun Tidak Mencukupi",
    "invalidRecuDate": "Tarikh Berulang Tidak Sah Dipilih",
    "invalidTONum": "Tidak Sah Pada Nombor Akaun",
    "invalidTrans": "Transaksi Tidak Sah",
    "counterPartyNotFound": "CounterParty tidak ditemui",
    "ofacFailed": "Semakan OFAC gagal",
    "interSORFail": "Urus niaga antara SOR tidak dibenarkan",
    "invalidRoutingNum": "Nombor penghalaan tidak sah",
    "requestedServiceNotAvail": "Pada masa ini perkhidmatan yang diminta tidak tersedia",
    "limitExceedGlobal": "Had global melebihi",
    "clientLimitExceed": "Had transaksi pelanggan melebihi had",
    "dailyLimitExceed": "Had transaksi harian melebihi",
    "invalidRecdetails": "Butiran penerima tidak sah",
    "unabletoQuote": "Tidak dapat sebut harga",
    "enterRecipntPhone": "Masukkan Nombor Telefon Penerima",
    "enterAccNumber": "Masukkan Nombor Akaun Penerima",
    "accountNotMatch": "Nombor Akaun tidak sepadan",
    "recipientAccount": "Akaun Penerima",
    "convertedAmount": "Jumlah Tertukar",
    "paymentSuccess": "Pembayaran Berjaya Dibuat!",
    "orderNumber": "Nombor Pesanan",
    "showing": "Menunjukkan",
    "of": "daripada",
    "refNumber": "Nombor rujukan",
    "details": "Butiran",
    "NoTransactionsFound": "Tiada Transaksi ditemui",
    "conntedTOQB": "Berjaya disambungkan ke QuickBooks",
    "QuoteConvertedAmount": "Jumlah Amaun Tertukar Sebut Harga",
    "QBConnectFailed": "Gagal menyambung ke QuickBooks",
    "conenctingToQB": "Menyambung ke QuickBooks, Sila tunggu…",
    "synchronizeFailed": "Gagal menyegerakkan QuickBooks",
    "synchronizeSuccess": "Berjaya menyegerakkan QuickBooks",
    "syncQBText": "Kekal Segerak dengan QuickBooks Dalam Talian",
    "accountConnectedToQB": "Akaun sudah disambungkan ke QuickBooks",
    "Password": "Kata laluan",
    "profileChangesSaved": "Perubahan profil anda telah disimpan",
    "Timezone": "Zon masa",
    "Locale": "Tempatan",
    "passwordUpdateSuccess": "Perubahan Kata Laluan anda telah berjaya disimpan",
    "passwordReqText": "Kata laluan mesti mengikut keperluan yang ditetapkan",
    "passwordNotMatch": "Dua kata laluan yang anda masukkan tidak sepadan",
    "newUserSuccess": "Pengguna baharu berjaya dibuat",
    "addNewUser": "Tambah Pengguna Baharu",
    "ssoReq": "SSO Diperlukan",
    "logoutTxt": "Berjaya log keluar",
    "updatePaymentSuccess": "Berjaya! telah berjaya dikemas kini Instrumen Pembayaran",
    "updatePaymentFailed": "Gagal mengemas kini Instrumen Pembayaran",
    "noDataFound": "Tiada data dijumpai",
    "submit": "Hantar",
    "completed": "Selesai",
    "failed": "Gagal",
    "pending": "Yang belum selesai",
    "sent": "hantar",
    "SessionExpired": "Sesi Tamat",
    "Uwallet": "Uwallet",
    "UWalletfunding": "Pembiayaan dompet U",
    "debit": "Debit",
    "credit": "Kredit",
    "CARD": "Kad",
    "BANK": "Bank",
    "active": "Aktif",
    "ccintl": "Kad Kredit Antarabangsa",
    "amex_dis": "Amex/Discover",
    "amex_card_text": "Tambah Kad AMEX",
    "nodata": "Tiada data",
    "note": "Nota",
    "load_fund_via_card": "Muatkan dana melalui kad",
    "load_fund_via_ach": "Muatkan dana melalui ACH",
    "load_fund_via_connector": "Muatkan dana melalui penyambung",
    "p2m_via_connector": "P2M melalui penyambung",
    "creditAdjustment": "Pelarasan Kredit",
    "debitAdjustment": "Pelarasan Debit",
    "startDateText": "Tarikh mula",
    "endDateText": "Tarikh tamat",
    "payIn": "Verify",
    "payOut": "Pembayaran",
    "checking": "Menyemak",
    "saving": "Menyimpan",
    "verify": "Sahkan",
    "reset": "Tetapkan semula",
    "today": "Hari ini",
    "a2a_reversal": "Pembalikan A2A",
    "credit_point": "Mata Kredit",
    "commission_payout_via_ach": "Pembayaran Komisen melalui ACH",
    "commission_payout_via_uWallet": "Pembayaran Komisen melalui UWallet",
    "commission_payout_via_payquicker": "Pembayaran Komisen melalui Payquicker",
    "withdraw_via_ach": "Mengeluarkan dana melalui ACH",
    "withdraw_via_payquicker": "Mengeluarkan dana melalui Payquicker",
    "credit_reversal": "Pembalikan Kredit",
    "load_fund_via_card_reversal": "Muatkan dana melalui pembalikan kad",
    "uwallet_funding_reversal": "Pembalikan pembiayaan Wallet U",
    "processing": "Memproses",
    "declined": "ditolak",
    "onHold": "Ditahan",
    "scheduled": "Dijadualkan",
    "cancelled": "Dibatalkan",
    "beneficiaryName": "Nama Penerima",
    "b_b_code": "Kod Bank & Kod Cawangan",
    "validBankCode": "Sila masukkan kod yang sah",
    "minBankCode": "Kod tidak boleh kurang daripada 7 digit.",
    "load_fund_wallet": "Memuatkan dana dompet ",
    "ach_transfer": "Pemindahan bayaran ACH (Rumah Penjelasan Automatik) ",
    "mater_visa": "Mastercard/Visa",
    "go_to_transaction": "Transaksi seterusnya",
    "p2m_reversal": "Batalan pembayaran pedagang (P2M)",
    "deposit_account_list": "Senarai akaun deposit",
    "load_fund_via_ach_reversal": "Memuatkan dana melalui pemutaran ACH (ACH reversal）",
    "load_fund_via_connector_reversal": "Memuatkan dana melalui pemutaran Connector (Connector reversal)",
    "KYC_Failed": "Pengesahan KYC Gagal",
    "updateKYCText": "Adakah anda ingin mengemas kini Butiran KYC?",
    "userType": "Jenis pengguna",
    "usertypeErr": "Sila pilih Jenis Pengguna.",
    "recidencyNumberTxt": "Nombor Penduduk",
    "validResNum": "Nombor Penduduk Diperlukan",
    "validresidencyNum": "Sila masukkan nombor pemastautin yang sah",
    "flexText": "PayQuicker Flex",
    "flexTextVietnam": "Thanh toán linh hoạt",
    "withdrawalConfig": "Konfigurasi Pengeluaran",
    "withdrawalConfiguration": "Konfigurasi Pengeluaran",
    "addConfig": "Tambah Konfigurasi",
    "addFreequencyConfig": "Tambah/Edit Konfigurasi Kekerapan",
    "ReceivingAccount": "Akaun Penerimaan",
    "deactivateConfig": "Adakah anda ingin menyahaktifkan Konfigurasi yang dipilih?",
    "updateConfigSuccess": "Berjaya! kemas kini Config berjaya",
    "updateConfigFailed": "Gagal mengemas kini Config",
    "add_card_text": "Tambah Kad Kredit",
    "UWalletTransfer": "Pemindahan UWallet",
    "daily": 'Setiap hari',
    "weekly": "Setiap minggu",
    "monthly": "Bulanan",
    "biweekly": "Dua kali seminggu",
    "immediate": "serta merta",
    "percentage": "Peratusan",
    "percentError": "Sila masukkan peratusan yang sah",
    "toDistributor": "Kepada Pengedar",
    "distributorId": "Sila masukkan ID Pengedar",
    "invalidDistributor": "Sila masukkan Id Pengedar yang sah",
    "distributorNotFound": "Pengedar tidak ditemui",
    "distributorKYCError": "Pengedar Penerima tidak disahkan",
    "decimalNotPermit": "Tempat perpuluhan tidak dibenarkan",
    "paybyCard": "Bayar Dengan Kad",
    "validIDNum": "Sila masukkan nombor yang sah",
    "swiftCode": "Kod Swift",
    "validBankName": "Sila masukkan nama bank yang sah",
    "JAPANPAYOUT": "Bank",
    "idNumber": "Nombor ID",
    "commissionHistory": "Sejarah Suruhanjaya",
    "CITCON WITHDRAWAL": "Pengeluaran Bank",
    "validCityName": "Sila masukkan nama bandar yang sah",
    "pendingApproval": "Menunggu Kelulusan",
    "purposeCode": "Tujuan Pembayaran",
    "plsSeletpurposeCode": "Pilih tujuan pembayaran",
    "QualifyingCredit": "Kredit Layak",
    "tooltipAccountName": "Sila masukkan nama penuh anda, termasuk nama pertama dan nama keluarga (jika individu) cth John Smith ATAU nama penuh syarikat sah anda (jika syarikat) cth ABC Limited",
    "tooltipAccountNumber": "Masukkan nombor akaun bank",
    "tooltipBankCode": "Masukkan kod bank",
    "tooltipBranchCode": "Masukkan kod cawangan",
    "tooltipSwiftBic": "Sila masukkan SWIFT BIC (Kod Identiti Bank) bank anda yang boleh sama ada 8 atau 11 aksara panjangnya. Jika SWIFT BIC hanya 8 aksara, JANGAN isikan kepada 11 aksara dengan menambah XXX",
    "tooltipAddress": "Sila masukkan nombor/nama rumah kediaman anda (jika individu) atau berdaftar (jika syarikat) dan nama jalan cth 1 Jalan Utama",
    "tooltipCity": "Sila masukkan bandar atau bandar kediaman anda (jika individu) atau alamat berdaftar (jika syarikat) cth London",
    "tooltipZipcode": "Sila masukkan poskod/poskod kediaman anda (jika individu) atau alamat berdaftar (jika syarikat)",
    "tooltipCountry": "Sila pilih negara kediaman anda (jika individu) atau alamat berdaftar (jika syarikat).",
    "tooltipDOB": "Sila pilih tarikh lahir anda (jika individu sahaja)",
    "tooltipBankName": "Sila masukkan nama bank anda",
    "bankCode": "Kod bank",
    "branchCode": "Kod Cawangan",
    "dob": "Tarikh lahir",
    "selct_currency_deposit": "mata wang",
    "pc_from_text": "Daripada",
    "pc_to_text": "Kepada",
    "pc_amount_text": "Jumlah",
    "CNY PAYOUT": "Bank (WP)",
    "accountHolderaddress": "Alamat Pemegang Akaun",
    "accountHolderName": "Nama Pemilik Akaun",
    "branchName": "Nama cawangan",
    "continue": "Teruskan",
    "validBranchName": "Sila masukkan nama cawangan yang sah",
    "SOLU": "Pengeluaran Bank",
    "cardUpdatedSuccessfully": "Kad berjaya dikemas kini",
    "reason": "Sebab",
    "failedUpdateCard": "Gagal mengemas kini kad",
    "requiredError": "Ruangan Perlu Diisi",
    "kycNotVerified": "KYC tidak disahkan.",
    "VND PAYOUT": "tarik diri WP",
    "phoneNumberAliWP": "86 XXX XXXX XXXX",
    "phoneTooltip": "Sila berikan nombor telefon penerima (contoh: 86 XXX XXXX XXXX)",
    "emailTooltip": "Masukkan alamat emel penerima",
    "ALIPAY PAYOUT WP": "Alipay WP"
}